<template>
  <div class="list">
    <list-search @onSearch="onSearch" @clearSearch="onSearch" :childData="childData"></list-search>
    <list-menu @buttonClick="buttonClick" :menuData="menudata" ></list-menu>
    <global-table @SelectionChange="SelectionChange" :key="'FscList'" :tableField="tableField" @tableAction="tableAction" :tableData="tableData" :paginationData="paginationData" @pageChange="pageChange"
    ></global-table>
  </div>
</template>

<script>
import { request } from '@/assets/js/http.js'
import { SearchList, SearchData, tableField } from './js/setting.js'
import Component from '@/assets/js/components.js'
import dayjs from 'dayjs'
export default {
  components: Component.components,
  name: 'FscList',
  data () {
    var pageSize = parseInt((document.documentElement.clientHeight - 280) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    return {
      tableField: tableField,
      tableData: [],
      rowChildren: [],
      paginationData: { pageSize: this.pageSize, total: 0 },
      pageNum: 1,
      selectData: [],
      searchData: { pageNum: 1, pageSize: pageSize },
      pageSize: pageSize,
      menudata: this.$store.state.ViewMenuData.menuData,
      childData: { searchList: SearchList, searchData: SearchData }
    }
  },
  created: function () {
    this.requestData(this.searchData)
  },
  methods: {
    SelectionChange (data) {
      this.selectData = data
    },
    buttonClick: function (data) {
      if (data.alias === 'AddFCS') {
        this.$router.push({ params: { viewName: 'PurchaseFscForm' } })
        this.$emit('OpenView', { openView: 'PurchaseFscForm' })
      } else {
        if (this.selectData.length > 0) {
          if (data.alias === 'DelFCS') {
            const ids = []
            for (const item of this.selectData) {
              ids.push(item.id)
            }
            request('/api/supplier/fcs/delete', 'delete', ids).then((Response) => {
              if (Response.code === '200') {
                this.$message({
                  message: '删除成功',
                  type: 'success'
                })
              }
              this.requestData(this.searchData)
            })
          }
          if (data.alias === 'Confirm') {
            for (const item of this.selectData) {
              if (item.status === 1 || item.status === 0) {
                item.status = 3
              } else if (item.status === 2) {
                item.status = 4
              }
            }
            request('/api/supplier/fcs/update', 'put', this.selectData).then((Response) => {
              if (Response.code === '200') {
                this.$message({
                  message: '确认成功',
                  type: 'success'
                })
              } else {
                this.requestData(this.searchData)
              }
            })
          }
        } else {
          this.$message({
            message: '请勾选列表的FCS',
            type: 'warning'
          })
        }
      }
    },
    onSearch: function (data) {
      this.searchData = { ...data }
      Object.keys(this.searchData).forEach((key) => {
        if (this.searchData[key] === 'All' || this.searchData[key] === '') {
          delete this.searchData[key]
        }
      })
      delete this.searchData.tempCreateDate
      if (data.tempCreateDate) {
        this.searchData.endTime = this.dateFormate(data.tempCreateDate[1])
        this.searchData.startTime = this.dateFormate(data.tempCreateDate[0])
      }
      this.searchData.pageNum = 1
      this.searchData.pageSize = this.pageSize
      this.requestData(this.searchData)
    },
    dateFormate: function (data) {
      return dayjs(data).format('YYYY-MM-DD')
    },
    tableAction: function (index, data, action) {
      if (action.id === '1' || action.id === '2') {
        if (action.id === '2') {
          if (this.tableData[index].status === 1 || this.tableData[index].status === 0) {
            this.tableData[index].status = 3
          } else if (this.tableData[index].status === 2) {
            this.tableData[index].status = 4
          }
          request('/api/supplier/fcs/update', 'put', this.tableData).then((Response) => {
            if (Response.code === '200') {
              this.$message({
                message: '确认成功',
                type: 'success'
              })
            } else {
              this.requestData(this.searchData)
            }
          })
        }
        data.judgeEdit = '2'
      } else {
        if (action.id === '3') {
          this.tableData[index].updateNum = this.rowChildren[index].rowData.updateNum
          request('/api/supplier/fcs/update', 'put', this.tableData).then((Response) => {
            if (Response.code === '200') {
              this.$message({
                message: '保存成功',
                type: 'success'
              })
            } else {
              this.requestData(this.searchData)
            }
          })
        }
        data.judgeEdit = '1'
      }
    },
    pageChange: function (data) {
      this.searchData.pageNum = data
      this.requestData(this.searchData)
    },
    requestData (data) {
      this.rowChildren = []
      request('/api/supplier/fcs/list', 'get', data).then((Response) => {
        if (Response.code === '200') {
          this.tableData = Response.data.records
          if (this.paginationData.total !== Response.data.total) {
            this.paginationData.total = Response.data.total
          }
        }
      })
    }
  },
  watch: {
    '$store.state.ViewMenuData': function (val) {
      this.menudata = val.menuData
    }
  }
}
</script>

<style scoped lang="scss">
.tableList :deep(.table){
  .el-table td {
    .el-input{
      line-height: 22px;
    }
    .el-input__inner {
      border: none;
      background: none;
      height: 20px;
      line-height: 20px;
      padding: 0;
    }
  }
}
</style>
